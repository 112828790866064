import { useQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { GRAPHQL_UNIDADE_PARAMETROS } from 'gql/unidade';
import { IQueryUnidadeParametros } from 'pages/unidades/list/hooks/useQueryFilterUnit/index.d';
import React, { createRef, FC, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'routes/auth.context';
import ComponentConfigValueAppAndKeyIFood from './Component';
import { useMutateUnidadeParametros } from './hooks/useMutateUnidadeParametros';
import options from './data/options-recalculo-frete.json';
import optionsFindIfood from './data/options-find-ifood.json';
import optionsMostrarValorTotal from './data/options-mostrar-valot-total.json';
interface IParams {
    unidadeId: string;
}

const PageUnidadeconfigValueAppAndKeyIFood: FC = () => {
    const { unidadeId } = useParams<IParams>();
    const { alertConfirm } = useContext(AuthContext);
    const { data, loading: loadingSetup } = useQuery<IQueryUnidadeParametros>(
        GRAPHQL_UNIDADE_PARAMETROS,
        {
            fetchPolicy: 'no-cache',
            variables: {
                unidadeId,
            },
            onError: err => console.error(err),
        },
    );

    const { atualizaUnidadeConfiguracoesParametros, loading } =
        useMutateUnidadeParametros();
    const rForm = createRef<FormHandles>();

    const handleSubmit = async data => {
        try {
            if (data.findIFood === 'YES' && data.clientId.trim().length === 0) {
                throw new Error(
                    'Para ativar a busca no IFood é necessário informar o Client ID e o Client Secret',
                );
            }

            await atualizaUnidadeConfiguracoesParametros({
                data: [
                    { chave: 'COLLECTT::WEBHOOK', valor: data.webhook.trim() },
                    { chave: 'CLIENT::ID', valor: data.clientId.trim() },
                    {
                        chave: 'TOKEN::ANOTA::AI',
                        valor: data.tokenAnotaAi.trim(),
                    },
                    {
                        chave: 'ID::LOJA::ANOTA::AI',
                        valor: data.idLojaAnotaAi.trim(),
                    },
                    {
                        chave: 'CALC::DIFF::FEE::INTEGRATION',
                        valor: data.recalculo,
                    },
                    {
                        chave: 'FIND::IFOOD',
                        valor: data.findIFood,
                    },
                    {
                        chave: 'AUTO::DISPATCH::ORDER',
                        valor: data.autoDispatchOrder,
                    },
                    {
                        chave: 'VIEW::VALOR::TOTAL::FRETE',
                        valor: data.mostrarValorTotal,
                    },
                ],
                unidadeId,
            });
            // @ts-ignore
        } catch ({ message }) {
            await alertConfirm({
                tipo: 'erro',
                titulo: 'Erro',
                conteudo: message as string,
            });
        }
    };
    const dataCp = useMemo(() => {
        if (!(!loadingSetup && !!(data && data.recuperarUnidadeParametros)))
            return [];
        const dt: { chave?: string; valor?: string }[] =
            data.recuperarUnidadeParametros;
        const clientId =
            dt.find(({ chave }) => chave === 'CLIENT::ID')?.valor || '';
        const tokenAnotaAi =
            dt.find(({ chave }) => chave === 'TOKEN::ANOTA::AI')?.valor || '';
        const idLojaAnotaAi =
            dt.find(({ chave }) => chave === 'ID::LOJA::ANOTA::AI')?.valor ||
            '';
        const webhook =
            dt.find(({ chave }) => chave === 'COLLECTT::WEBHOOK')?.valor || '';

        const calcFee =
            dt.find(({ chave }) => chave === 'CALC::DIFF::FEE::INTEGRATION')
                ?.valor || 'NOT';
        const viewValorTotalFrete =
            dt.find(({ chave }) => chave === 'VIEW::VALOR::TOTAL::FRETE')
                ?.valor || 'YES';
        const findIFood =
            dt.find(({ chave }) => chave === 'FIND::IFOOD')?.valor || 'NOT';
        const autoDispatchOrder =
            dt.find(({ chave }) => chave === 'AUTO::DISPATCH::ORDER')?.valor || '';
        const response = [
            {
                type: 'text',
                label: 'Webhook atualizações de coleta',
                placeholder: 'URL do webhook',
                name: 'webhook',
                toolTipLegend:
                    'webhook - Esta URL será utilizada para receber as atualizações de coleta',
                defaultValue: webhook,
            },
            {
                type: 'text',
                label: 'Tempo de auto despacho',
                placeholder: 'Tempo de auto despacho em minutos',
                name: 'autoDispatchOrder',
                toolTipLegend:
                    'Aqui você pode definir o tempo de auto despacho em minutos',
                defaultValue: autoDispatchOrder,
            },
            {
                type: 'text',
                label: 'ID da loja iFood',
                placeholder: 'ID da loja iFood',
                name: 'clientId',
                toolTipLegend:
                    'ClientId - Esta informação serve para capturar as corridas do IFood',
                defaultValue: clientId,
            },
            {
                type: 'text',
                label: 'Token Anota ai',
                placeholder: 'Token Anota ai',
                name: 'tokenAnotaAi',
                toolTipLegend:
                    'Token Anota Ai - Esta informação serve para capturar as corridas do Anota Ai',
                defaultValue: tokenAnotaAi,
            },
            {
                type: 'text',
                label: 'ID da loja Anota ai',
                placeholder: 'ID da loja Anota ai',
                name: 'idLojaAnotaAi',
                toolTipLegend:
                    'Id Loja Anota Ai - Esta informação serve para capturar as corridas do Anota Ai',
                defaultValue: idLojaAnotaAi,
            },
            {
                type: 'select-simple',
                label: 'Mostrar valor Frete + Retorno + Porcentagem',
                placeholder: 'Mostra o valor total',
                name: 'mostrarValorTotal',
                toolTipLegend:
                    'Caso marque a opção  "SIM". No sistema irá mostrar o valor total do frete mostrando Frete + Retorno + Porcentagem Jogo Rápido.',
                defaultValue: optionsMostrarValorTotal.find(
                    ({ value }) => value === viewValorTotalFrete,
                ),
                options: optionsMostrarValorTotal,
            },
            {
                type: 'select-simple',
                label: 'Somar frete do cliente no APP',
                placeholder: 'Somar frete cliente',
                name: 'recalculo',
                toolTipLegend:
                    'Caso marque a opção  "SIM". No APP do entregador ao "Finalizar corrida" será somado o valor do frete do cliente com o valor do produto. Caso o cliente não queira somar estes valores, basta enviar o frete com o valor iqual a zero.',
                defaultValue: options.find(({ value }) => value === calcFee),
                options,
            },
            {
                type: 'select-simple',
                label: 'Buscar corridas IFood',
                placeholder: 'Buscar corridas IFood',
                name: 'findIFood',
                toolTipLegend: 'Buscar corridas IFood',
                defaultValue: optionsFindIfood.find(
                    ({ value }) => value === findIFood,
                ),
                options: optionsFindIfood,
            },
        ];
        return response;
    }, [data]);

    const oProps = {
        data: dataCp,
        loading,
        rForm,
        handleSubmit,
    };

    return <ComponentConfigValueAppAndKeyIFood {...oProps} />;
};
export default PageUnidadeconfigValueAppAndKeyIFood;
