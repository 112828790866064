import { gql } from '@apollo/client';
export const GRAPHQL_LISTAR_RELATORIO_ENTREGAS_ANALITICO: any = gql`
    query relatorioEntregasAnalitico(
        $dataInicial: String!
        $dataFinal: String!
        $coletaId: [ID]
        $entregadorId: [ID]
        $periodoId: [ID]
    ) {
        relatorioEntregasAnalitico(
            dataInicial: $dataInicial
            dataFinal: $dataFinal
            coletaId: $coletaId
            entregadorId: $entregadorId
            periodoId: $periodoId
        ) {
            data
            coletaId
            entregadorId
            entregadorNome
            periodoId

            modalTransporte

            valorFrete
            valorTotalProdutos
            valorGarantido
            valorTaxaRetorno
            usarTaxaRetorno
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_SINTETICO: any = gql`
    query relatorioEntregasAnaliticoPeriodoDiaSintetico(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioEntregasAnaliticoPeriodoDiaSintetico(
            dataInicial: $dataInicio
            dataFinal: $dataFim
            formaPagamento: $formaPagamento
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            data
            modalTransporte
            periodoNome
            identificador
            nomeFantasia
            porcentagemNegociacao
            qtdeColetas
            valorFrete
            valorRetorno
            totalEntregador
            taxaJogoRapido
            totalAReceberUnidade
            valorProduto
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_UNIDADE_SINTETICO: any = gql`
    query relatorioUnidadesSintetico(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioUnidadesSintetico(
            dataInicial: $dataInicio
            formaPagamento: $formaPagamento
            dataFinal: $dataFim
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            identificador
            nomeFantasia
            modalTransporte
            porcentagemNegociacao
            qtdeColetas
            valorRetorno
            valorAdicionalCorrida
            valorFrete
            totalEntregador
            taxaJogoRapido
            totalAReceberUnidade
            valorProduto
            emailFinanceiro
            whatsappFinanceiro
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_ANALITICO: any = gql`
    query relatorio(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioEntregasAnalitico(
            dataInicial: $dataInicio
            dataFinal: $dataFim
            formaPagamento: $formaPagamento
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            prePago
            dataConfirmacaoAceite
            modalTransporte
            dataPedido
            dataPrimeiraChamada
            coletaDataCheckoutUnidade
            coletaDataCheckinUnidade
            coletaDataCheckoutCliente
            coletaDataCheckinCliente
            unidadeNomeFantasia
            numeroInterno
            ifoodDisplayId
            enderecoBairro
            enderecoCep
            entregadorNome
            clienteNome
            linkRotaGoogleMaps
            distanciaPercorrida
            periodoNome
            valorFrete
            valorTotalProdutos
            valorGarantido
            valorTaxaRetorno
            porcentagemNegociacao
            valorPercNegociado
        }
    }
`;

export const GRAPHQL_LISTAR_RELATORIO_ANALITICO_ENTREGADOR: any = gql`
    query relatorio(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioEntregadorAnalitico(
            dataInicial: $dataInicio
            formaPagamento: $formaPagamento
            dataFinal: $dataFim
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            modalTransporte
            dataConfirmacaoAceite
            coletaDataCheckoutUnidade
            coletaDataCheckinUnidade
            coletaDataCheckoutCliente
            coletaDataCheckinCliente
            unidadeNomeFantasia
            numeroInterno
            entregadorNome
            clienteNome
            distanciaPercorrida
            periodoNome
            valorFrete
            valorAdicionalCorridaEntregador
            valorTotalProdutos
            valorGarantido
            valorTaxaRetorno
            porcentagemNegociacao
            valorPercNegociado
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_RECUSADAS_SINTETICO: any = gql`
    query relatorio(
        $dataInicio: String!
        $dataFim: String!
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioColetasRecusadasSintetico(
            dataInicial: $dataInicio
            dataFinal: $dataFim
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            unidadeNomeFantasia
            entregadorNome
            periodoRecusaNome
            qtdeRecusasTotais
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_RECUSADAS_ANALITICO: any = gql`
    query relatorio(
        $dataInicio: String!
        $dataFim: String!
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioColetasRecusadasAnalitico(
            dataInicial: $dataInicio
            dataFinal: $dataFim
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            coletaData
            unidadeNomeFantasia
            coletaNumeroInterno
            coletaRecusaData
            entregadorNome
            periodoRecusaNome
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO: any = gql`
    query relatorioPlayerPgtoSintetico(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioPlayerPgtoSintetico(
            dataInicial: $dataInicio
            formaPagamento: $formaPagamento
            dataFinal: $dataFim
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            entregadorNome
            endLogradouro
            endNumero
            endComplemento
            endBairro
            endCEP
            cpfCnpj
            tipoPessoa
            qtdeColetas
            valorPercNegociado
            valorTotalTaxaRetorno
            valorTotalFrete
            valorTotalAdicionalCorridaEntregador
            valorGarantidoPgto
            titularCpf
            contaBancoId
            contaAgencia
            contaAgenciaDv
            contaNumero
            contaNumeroDv
            contaTipo
            dadosAtualizado
            pixConta
            pixTipo
        }
    }
`;
export const GRAPHQL_LISTAR_RELATORIO_PLAYER_PGTO_SINTETICO_DIA: any = gql`
    query relatorioPlayerPgtoSinteticoDia(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $unidadeId: [ID]
        $periodoId: [ID]
        $entregadorId: [ID]
    ) {
        relatorioPlayerPgtoSinteticoDia(
            dataInicial: $dataInicio
            formaPagamento: $formaPagamento
            dataFinal: $dataFim
            unidadeId: $unidadeId
            periodoId: $periodoId
            entregadorId: $entregadorId
        ) {
            nome
            qtdeColetas
            valorTotalTaxaRetorno
            distanciaPercorrida
            valorTotalFrete
            valorTotalAdicionalCorridaEntregador
            valorGarantidoPgto
            cpf
            turno
            modal
            data
        }
    }
`;
export const GRAPHQL_INTERESSE_ESCALA: any = gql`
    query relatorioEntregadoresEscala(
        $dataInicio: String!
        $dataFim: String!
        $formaPagamento: Int
        $periodoId: ID
    ) {
        relatorioEntregadoresEscala(
            dataInicio: $dataInicio
            dataFim: $dataFim
            formaPagamento: $formaPagamento
            periodoId: $periodoId
        ) {
            nomeCompleto
            interest
            scaled
        }
    }
`;
